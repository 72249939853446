<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!equipmentCategory">
      <span class="loader"></span>
    </span>
    <equipment-category-form
      v-if="equipmentCategory"
      :loading="loading"
      :equipmentCategoryData="equipmentCategory"
      :formErrors="formErrors"
      @equipmentCategorySubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseEquipmentCategoryModal="onCloseEquipmentCategoryModal"
    />
  </div>
</template>

<script>
import EquipmentCategoryForm from "../partials/EquipmentCategoryForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { EquipmentCategoryForm },

  mixins: [alertLeave],

  props: {
    equipmentCategoryId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      equipmentCategory: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "equipmentCategories/get",
          this.equipmentCategoryId
        );
        this.equipmentCategory =
          this.$store.getters["equipmentCategories/equipmentCategory"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    onCloseEquipmentCategoryModal() {
      this.$emit("onViewEquipmentCategory");
    },

    async handleSubmit(equipmentCategory) {
      this.loading = true;
      const equipmentCategoryData = cloneDeep(equipmentCategory);

      try {
        await this.$store.dispatch(
          "equipmentCategories/update",
          equipmentCategoryData
        );
        this.$notify({
          type: "success",
          message: this.$t("EQUIPMENT_CATEGORIES.EQUIPMENT_CATEGORY_UPDATED"),
        });
        const equipmentCategory = await this.$store.getters[
          "equipmentCategories/equipmentCategory"
        ];
        this.$emit("onViewEquipmentCategory", equipmentCategory, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>

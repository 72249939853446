<template>
  <div class="container-fluid">
    <equipment-category-form
      :loading="loading"
      :equipmentCategoryData="equipmentCategory"
      :formErrors="formErrors"
      @equipmentCategorySubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseEquipmentCategoryModal="onCloseEquipmentCategoryModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultEquipmentCategory from "../defaultEquipmentCategory";
import EquipmentCategoryForm from "../partials/EquipmentCategoryForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    EquipmentCategoryForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      equipmentCategory: cloneDeep(defaultEquipmentCategory),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(equipmentCategory) {
      this.loading = true;
      const equipmentCategoryData = cloneDeep(equipmentCategory);
      delete equipmentCategoryData.id;
      try {
        await this.$store.dispatch(
          "equipmentCategories/add",
          equipmentCategoryData
        );
        this.$notify({
          type: "success",
          message: this.$t("EQUIPMENT_CATEGORIES.EQUIPMENT_CATEGORY_ADDED"),
        });
        const equipmentCategory = await this.$store.getters[
          "equipmentCategories/equipmentCategory"
        ];
        this.$emit("onViewEquipmentCategory", equipmentCategory, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseEquipmentCategoryModal() {
      this.$emit("onCloseEquipmentCategoryModal");
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!equipmentCategory">
      <span class="loader"></span>
    </span>
    <div v-if="equipmentCategory" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>
            {{ $t("EQUIPMENT_CATEGORIES.DETAILS_VIEW_EQUIPMENT_CATEGORY") }}
          </h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img
                  src="../../../../../../public/img/kw-home.svg"
                  alt="icon"
                />
                <span>{{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}</span>
              </li>
              <li>
                <span>{{ equipmentCategory.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            @click="deleteEquipmentCategory"
            v-if="
              $currentUserCan($permissions.PERM_DELETE_EQUIPMENT_CATEGORIES)
            "
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editEquipmentCategory"
            v-if="$currentUserCan($permissions.PERM_EDIT_EQUIPMENT_CATEGORIES)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="equipmentCategory"
      >
        <tab-pane title="equipmentCategory" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <equipment-category-view-global
            :equipmentCategory="equipmentCategory"
          />
        </tab-pane>

        <tab-pane title="equipments" id="2" :active="false">
          <span slot="title">
            {{ $t("EQUIPMENTS.EQUIPMENT_LIST") }}
          </span>
          <equipment-list-table :filterCategory="equipmentCategory.id" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import EquipmentCategoryViewGlobal from "../partials/EquipmentCategoryViewGlobal.vue";
import EquipmentListTable from "../../EquipmentManagement/partials/EquipmentListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    EquipmentCategoryViewGlobal,
    EquipmentListTable,
  },

  mixins: [],

  props: {
    equipmentCategoryId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      equipmentCategory: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "equipmentCategories/get",
          this.equipmentCategoryId
        );
        this.equipmentCategory =
          this.$store.getters["equipmentCategories/equipmentCategory"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editEquipmentCategory() {
      this.$emit("onEditEquipmentCategory", this.equipmentCategory);
    },

    deleteEquipmentCategory() {
      this.$emit("onDeleteEquipmentCategory", this.equipmentCategory);
    },
  },
};
</script>
